import { Loading } from "components/loading/loading-component"
import { Headers } from 'components/header/headers-component';
import { TitleGroupComponent } from "components/title/title-group-component";
import { Row, Col, Button, Badge, Card, Form, Pagination, Table, Tooltip } from "react-bootstrap";
import { BsFillCartFill, BsPercent, BsSliders, BsTag } from "react-icons/bs";
import { Footer } from "components/footer/footer-component";
import { ParseDateToBrV2 } from "helper/format-date-helper";
import { AiFillLock } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { TableDescriptions } from "../../../@types/interfaces/table-interface";
import { orderValueColums } from "helper/table-columns-helper";
import { getParameterUrlByName, parseLinkHeader } from "helper/request-helper";
import { getEnv } from "helper/window-helper";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { ModalOrderHideColumns } from "./components/modal/modal-order-hide-columns-component";
import { getLocalStorage, updateLocalStorege } from "data/cache/localstorage-cache";
import { LinkHeader } from "../../../@types/interfaces/link-header-interface";
import { ColumnsActiveCampaignAdapter } from "helper/adapter/columsTable/columns-table-active-campaign-adapter";
import Select from "react-select";
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface";
import { FaArrowRight, FaArrowLeft, FaArrowUp, FaArrowDown, FaArrowsAltH } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { GrCircleInformation, GrStatusCritical } from "react-icons/gr";
import { HiBell } from "react-icons/hi";
import { MdMapsHomeWork, MdAttachMoney } from "react-icons/md";
import { TbSitemap } from "react-icons/tb";
import { ModalPlanning } from "./components/modal/modal-planning-component";
import { FiltersDefault } from "../../../@types/interfaces/filter-interface";
import { ModalIcon } from "./components/modal/modal-icon-component";
import { TableHeaderStyled } from "pages/style/table/table-header";
import { LineChart, CartesianGrid, XAxis, YAxis, Legend, Line } from "recharts";
import { ModalConfirmAction } from "components/modal/modal-confirm-action-component";
import { BiColumns } from "react-icons/bi";
import { ActiveCampaignTotals } from "../../../@types/interfaces/analysis/active-campaign-totals-interface";
import '../../style/analysis/active-campaigns.css'
import { ActiveCampaignTotalsCard } from "./components/active-campaignd-totals-card-compnent";

type Props = {
  isLoading: boolean
  loggedUserDataRole: string[]
  list: (params: { pagination: string; model?: any; idBranch?: number; idCampaign?: number }) => Promise<any>;
  save: (params: { idIntencao: number, item: any[] }) => Promise<any>;
  getActiveCampaignsTotals: (idIntencao: number, idBranch: number, idCampaign: number) => Promise<any>;
  removePendingQuantities: (idIntencao: number) => Promise<any>;
  sendOrder: (idIntencao: number, idBranch: number, idCampaign: number) => Promise<any>;
  branchOptions: SelectOptionInterface[] | undefined,
  campaignsOptions: SelectOptionInterface[] | undefined,
  findCampaigns: (idBranch: number) => Promise<any>;
  createPlanning: (params?: any) => Promise<any>;
  editPlanning: (params?: any) => Promise<any>;
  removePlanning: (params?: any) => Promise<any>;
  listAllPlanning: (params?: any) => Promise<any>;
  optionsFiltersDefault: FiltersDefault[];
  graphicHistory: (params?: any) => Promise<any>;
  observationItem: (params?: any) => Promise<any>;
  movement: (params?: any) => Promise<any>;
  correlate: (params?: any) => Promise<any>;
}

export const ActiveCampaignsPage: React.FC<Props> = ({
  isLoading,
  loggedUserDataRole,
  list,
  save,
  getActiveCampaignsTotals,
  removePendingQuantities,
  sendOrder,
  branchOptions,
  campaignsOptions,
  findCampaigns,
  createPlanning,
  editPlanning,
  removePlanning,
  listAllPlanning,
  optionsFiltersDefault,
  graphicHistory,
  observationItem,
  movement,
  correlate,

}) => {

  const LOCALSTORAGE_COLUMS = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_ACTIVE_CAMPAIGN')!;
  const LOCALSTORAGE_COLUMS_ORDER = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_ACTIVE_CAMPAIGN')! + '_order';
  const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_ACTIVE_CAMPAIGN')!;


  const titlePage = 'Campanhas Ativas';
  //TODO Veririfcar 3 primeiras colunas apra ajustar tamanho e não mexe-las
  let freeze: boolean = false;
  let freezeBody: boolean = false;

  let firstCol: string = 'first-col-active-campaign';
  let secondCol: string = 'second-col-active-campaign';
  let thirdCol: string = 'third-col-active-campaign';
  let firstColHeader: string = 'first-col-header-active-campaign';
  let secondColHeader: string = 'second-col-header-active-campaign';
  let thirdColHeader: string = 'third-col-header-active-campaign';
  let freezeCss: string = '';

  const [branchSelected, updateBranchSelected] = useState<SelectOptionInterface | null>();
  const [campaignSelected, updateCampaignSelected] = useState<SelectOptionInterface>();
  const [activeCampaigntotals, updateActiveCampaigntotals] = useState<ActiveCampaignTotals>();
  const [previousQuantity, updatePreviousQuatity] = useState<number>();

  var totalPageSize: number = 50;
  const [firstPagination, updateFirstPagination] = useState<string>('');
  const [lastPagination, updateLastPagination] = useState<string>('');
  const [nextPagination, updateNextPagination] = useState<string>('');
  const [prevPagination, updatePrevPagination] = useState<string>('');
  const [paginationItemList, updatePaginationItemList] = useState<{ item: number; active: boolean; }[]>();

  const [showModalIcon, updateShowModalIcon] = useState(false);
  const [typeModalIcon, updateTypeModalIcon] = useState<string>();
  const [dataModalIcon, updateDataModalIcon] = useState<ReactNode>(<></>);
  const [dataRowModalIcon, updateDataRowModalIcon] = useState<any>();

  const [columns, updateColumns] = useState<TableDescriptions[]>(ColumnsActiveCampaignAdapter);
  const [listTable, updateListTable] = useState<any[]>([]);
  const [listOriginalDataTable, updateListOriginalDataTable] = useState<any[]>([]);
  const [idIntencaoCompra, updateIdIntencaoCompra] = useState<number>()

  const [showModalPlanning, updateShowModalPlanning] = useState(false);
  const [itemPlanning, updateItemPlanning] = useState<number>(0);
  const closePlanning = () => updateShowModalPlanning(false);
  const showPlanning = () => updateShowModalPlanning(true);

  const showIcon = () => updateShowModalIcon(true);

  const [showModalColumns, updateShowModalColumns] = useState(false);
  const closeColumn = () => updateShowModalColumns(false);
  const showColumn = () => updateShowModalColumns(true);

  const [showModalConfirmAction, updateShowModalConfirmAction] = useState(false);
  const openModalConfirmAction = () => updateShowModalConfirmAction(true);
  const closeModalConfirmAction = () => updateShowModalConfirmAction(false);

  useEffect(() => {
    const columsLocalStorage = getLocalStorage(LOCALSTORAGE_COLUMS);
    const planningLocalStorage = getLocalStorage(LOCALSTORAGE_PLANNING);

    if (planningLocalStorage) updateItemPlanning(planningLocalStorage.length - 1);

    if (columsLocalStorage)
      updateColumns(columsLocalStorage.sort((a: { order: number; }, b: { order: number; }) => (a.order > b.order ? 1 : -1)));

    updateLocalStorege(LOCALSTORAGE_COLUMS_ORDER, []);
  }, []);

  useMemo(() => {
    if (branchSelected !== null && branchSelected !== undefined) {
      findCampaigns(branchSelected.value)
    }
  }, [branchSelected])

  useMemo(() => {
    if (campaignSelected !== null && campaignSelected !== undefined) {
      handleTable()
    }
  }, [campaignSelected])

  useEffect(() => {
    if (typeModalIcon === 'Saldo') handleIconSaldo();
    if (typeModalIcon === 'Histórico') handleGraphicHistory();
    if (typeModalIcon === 'Venda') handlehandleMovement('venda');
    if (typeModalIcon === 'Compra') handlehandleMovement('compra');
    if (typeModalIcon === 'Correlatos') handlehandleCorrelate();
    if (typeModalIcon === 'Observacao') handleObservationItem();
  }, [typeModalIcon, updateTypeModalIcon]);

  useEffect(() => {
    const namePage = "active_campaigns";
    localStorage.setItem("actual_page", namePage);

    const local = getLocalStorage(LOCALSTORAGE_PLANNING);
    if (local !== null) {
      let countPlanning = local.length;
      updateItemPlanning(countPlanning);
    }
  }, [handleApplyPlanning]);

  const closeIcon = () => {
    updateShowModalIcon(false);
    updateTypeModalIcon('');
    updateDataModalIcon(<></>);
  };

  function handleOrderHideColumns(columns: TableDescriptions[]) {
    updateLocalStorege(
      LOCALSTORAGE_COLUMS,
      columns.sort((a, b) => (a.order > b.order ? 1 : -1))
    );
    updateColumns(columns.sort((a, b) => (a.order > b.order ? 1 : -1)));
  }

  const setValuePaginations = (paginationsActive: number, prev: number, next: number) => {
    let modelPaginationList: any[] = [];
    for (let index = paginationsActive; index < 6 + paginationsActive; index++) {
      if (!(index > next))
        modelPaginationList.push({
          item: index,
          active: index === paginationsActive ? true : false,
        });
    }
    updatePaginationItemList(modelPaginationList);
  };
  const onClickItemPagination = async (pagination: any) => {
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    await handleTable({
      pagination: `?page=${pagination.item}&size=${totalPageSize}`,
      page: parseInt(pagination.item),
      size: totalPageSize,
    });
  };
  const onClickFirstPagination = async () => {
    const size: string = getParameterUrlByName('size', firstPagination)!;
    await handleTable({
      pagination: `?page=${0}&size=${size}`,
      page: 0,
      size: parseInt(size),
    });
  };
  const onClickLastPagination = async () => {
    const page: string = getParameterUrlByName('page', lastPagination)!;
    const size: string = getParameterUrlByName('size', lastPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };
  const onClickNextPagination = async () => {
    const page: string = getParameterUrlByName('page', nextPagination)!;
    const size: string = getParameterUrlByName('size', nextPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };
  const onClickPrevPagination = async () => {
    const page: string = getParameterUrlByName('page', prevPagination)!;
    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };

  async function handleTable(params?: { pagination: string; page: number; size: number; ordination?: string }) {
    let pagePagination;
    let response;
    if (params) {
      response = await list({
        pagination: params.pagination,
        idBranch: branchSelected?.value,
        idCampaign: campaignSelected?.value
      });
      pagePagination = params.page;
    } else {
      pagePagination = 0;
      response = await list({
        pagination: `?page=${pagePagination}&size=${totalPageSize}`,
        idBranch: branchSelected?.value,
        idCampaign: campaignSelected?.value
      });
    }
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(response.headers['link']) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(pagePagination, parseInt(getParameterUrlByName('page', links.first)!), parseInt(getParameterUrlByName('page', links.last)!));
    }
    if (response.success) {
      updateIdIntencaoCompra(response.data.idIntencaoCompra)
      updateListOriginalDataTable(response.data.analiticoCampanha)
      updateActiveCampaigntotals(response.data.totais)
      changeValueListByColums(response.data.analiticoCampanha);
    } else {
      changeValueListByColums([])
      updateIdIntencaoCompra(undefined)
      updateCampaignSelected(undefined)
    }
  }

  function changeValueListByColums(model: any[]) {
    if (model !== undefined) {
      var ListTableChanged: any[] = [];
      var columsName = columns
        .filter((value) => value.hidden === false)
        .map((vl) => {
          return vl.dataField;
        });

      model.forEach((value) => {
        const filtered = Object.keys(value)
          .filter((key) => columsName.includes(key))
          .reduce((obj: any, key: any) => {
            obj[key] = value[key];
            return obj;
          }, {});

        ListTableChanged.push(filtered);
      });
      updateListTable(ListTableChanged)
    }
  }

  const handleOnFocusQuantity = () => async (event: React.FocusEvent<HTMLInputElement>) => {
    updatePreviousQuatity(parseInt(event.target.value))
  }

  const handleInputBlurQuantity = (value: any) => async (event: React.FocusEvent<HTMLInputElement>) => {
    if (idIntencaoCompra !== null && idIntencaoCompra !== undefined
      && parseInt(event.target.value) !== previousQuantity) {

      value['qtdPendente'] = Number(event.target.value)
      let updatedItem = listOriginalDataTable.find(val => val.fitStrCodFabricante === value['fitStrCodFabricante'])
      updatedItem.qtdPendente = Number(event.target.value)

      let response = await save({ idIntencao: idIntencaoCompra!, item: [updatedItem] })

      if (response.success) {
        handleTable()
      }
    }
  };

  async function confirmSendOrder() {
    closeModalConfirmAction()
    let response = await sendOrder(idIntencaoCompra!, branchSelected?.value, campaignSelected?.value)
    if (response.success) {
      handleTable()
    }
  }

  async function removeQuantities() {
    if (idIntencaoCompra !== null && idIntencaoCompra !== undefined) {
      removePendingQuantities(idIntencaoCompra)
      handleTable()
    }
  }

  async function handleApplyPlanning(model: any) {
    closePlanning();
    updateLocalStorege(LOCALSTORAGE_PLANNING, model);
    handleTable()
  }

  async function handleIconSaldo() {
    let model = [
      {
        type: 'CODIGO_ITEM',
        params: { codigos: [dataRowModalIcon.codigo] },
      },
      {
        type: 'FILIAL',
        params: { filiais: [dataRowModalIcon.filial], type: 'NOT_IN' },
      },
    ];

    let response = await list({ pagination: `?page=${0}&size=${totalPageSize}`, model: model, });
    if (response.statusCode !== 500) {
      let data = response.body;
      if (data.length > 0) {
        updateDataModalIcon(
          <div className="my-2">
            <p>Estoque da Filial:</p>
            <TableHeaderStyled>
              <Table>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Filial</th>
                    <th style={{ textAlign: 'left' }}>Peça</th>
                    <th style={{ textAlign: 'center' }}>Sufixo</th>
                    <th style={{ textAlign: 'right' }}>Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center">{dataRowModalIcon.filial}</td>
                    <td align="left">{dataRowModalIcon.nome}</td>
                    <td align="center">{dataRowModalIcon.codigo}</td>
                    <td align="right">{dataRowModalIcon.estoque}</td>
                  </tr>
                </tbody>
              </Table>
            </TableHeaderStyled>
            <p className="mt-4">Peças disponíveis em outras filiais:</p>
            <TableHeaderStyled>
              <Table size="sm" style={{ cursor: 'pointer' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Filial</th>
                    <th style={{ textAlign: 'center' }}>Base</th>
                    <th style={{ textAlign: 'right' }}>Pend</th>
                    <th style={{ textAlign: 'right' }}>Bloq</th>
                    <th style={{ textAlign: 'center' }}>CVF</th>
                    <th style={{ textAlign: 'left' }}>Status</th>
                    <th style={{ textAlign: 'right' }}>Exc</th>
                    <th style={{ textAlign: 'right' }}>Qtd Disp</th>
                    <th style={{ textAlign: 'right' }}>Prç Rep</th>
                    <th style={{ textAlign: 'right' }}>Cst Médio</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((value: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td align="center">{value.siglaFilial}</td>
                        <td align="center">{value.base}</td>
                        <td align="right">{value.pendente}</td>
                        <td align="right">{value.bloqueado}</td>
                        <td align="center">{value.curva}</td>
                        <td align="left">{value.status}</td>
                        <td align="right">{value.excesso}</td>
                        <td align="right">{value.estoque}</td>
                        <td align="right">{value.precoReposicao}</td>
                        <td align="right">
                          {"R$ " + (Math.ceil(value.custoMedio * 100) / 100).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableHeaderStyled>
          </div>
        );
      } else {
        updateDataModalIcon(
          <>
            <p>sem dados</p>
          </>
        );
      }
    } else {
      closeIcon();
    }
  }

  async function handleGraphicHistory() {
    let response = await graphicHistory(dataRowModalIcon.id);
    if (response.statusCode === 200) {
      let data = response.body.map((value: any) => {
        return {
          name: value.anoMes,
          precoReal: value.precoReal,
          precoReposicao: value.precoReposicao,
          precoVenda: value.precoVenda,
        };
      });

      if (data.length > 0) {
        updateDataModalIcon(<Graphic data={data} />);
      } else {
        updateDataModalIcon(
          <>
            <p>sem dados</p>
          </>
        );
      }
    } else {
      closeIcon();
    }
  }

  function Graphic(params: { data: any }) {
    return (
      <LineChart width={750} height={300} data={params.data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="precoReal" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="precoReposicao" stroke="#79aed2" />
        <Line type="monotone" dataKey="precoVenda" stroke="#82ca9d" />
      </LineChart>
    );
  }

  async function handleObservationItem() {
    let response = await observationItem({ codigo: dataRowModalIcon.codigo, filial: dataRowModalIcon.filial });
    if (response.statusCode === 200) {
      if (response.body.length > 0) {
        updateDataModalIcon(
          <TableHeaderStyled>
            <Table className="mt-3">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Mensagem</th>
                </tr>
              </thead>
              <tbody>
                {response.body.map((value: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{value.id}</td>
                      <td>{value.mensagem}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableHeaderStyled>
        );
      } else {
        updateDataModalIcon(
          <>
            <p>sem dados</p>
          </>
        );
      }
    } else {
      closeIcon();
    }
  }

  async function handlehandleMovement(typeMoviment: string) {
    let response = await movement({ codigoItem: dataRowModalIcon.codigo, idFilial: dataRowModalIcon.filial, typeMoviment: typeMoviment });
    if (response.statusCode === 200) {
      if (response.body.length > 0) {
        updateDataModalIcon(
          <TableHeaderStyled>
            <Table className="mt-3">
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Empresa</th>
                  <th style={{ textAlign: 'left' }}>Filial</th>
                  <th style={{ textAlign: 'right' }}>Quantidade</th>
                  <th style={{ textAlign: 'right' }}>Valor</th>
                </tr>
              </thead>
              <tbody>
                {response.body.map((value: any, index: number) => {
                  let valorFormatado = Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(value.valor);
                  return (
                    <tr key={index}>
                      <td align="left">{value.nomeEmpresa}</td>
                      <td align="left">{value.nomeFilial}</td>
                      <td align="right">{value.quantidade}</td>
                      <td align="right">{valorFormatado}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableHeaderStyled>
        );
      } else {
        updateDataModalIcon(
          <>
            <p>sem dados</p>
          </>
        );
      }
    } else {
      closeIcon();
    }
  }

  async function handlehandleCorrelate() {
    let response = await correlate({ codigoItem: dataRowModalIcon.codigo });
    if (response.statusCode === 200) {
      if (response.body.length > 0) {
        updateDataModalIcon(
          <>
            <p>Item(s) Correlatos:</p>
            <TableHeaderStyled>
              <Table className="mt-1">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Código</th>
                    <th style={{ textAlign: 'left' }}>Nome</th>
                    <th style={{ textAlign: 'right' }}>Estoque</th>
                  </tr>
                </thead>
                <tbody>
                  {response.body.map((value: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td align="center">{value.codigo}</td>
                        <td align="left">{value.nome}</td>
                        <td align="right">{value.estoque}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableHeaderStyled>
          </>
        );
      } else {
        updateDataModalIcon(
          <>
            <p>sem dados</p>
          </>
        );
      }
    } else {
      closeIcon();
    }
  }

  return (
    <>
      <Headers />
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="mx-5">
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className="d-flex align-items-center justify-content-end">
                  <Button variant="outline-primary" className="d-flex align-items-center"
                    onClick={showPlanning} size="sm" disabled={campaignSelected === null || campaignSelected === undefined}>
                    {itemPlanning > 0 ? (
                      <Badge bg="danger" className="me-2">
                        {itemPlanning}
                      </Badge>
                    ) : (
                      <></>
                    )}
                    <BsSliders className="me-1" />
                    Planejamento
                  </Button>
                  <div data-tip data-for="enviar_pedido" >
                    <Button className="ms-1" variant="primary" onClick={openModalConfirmAction} size="sm"
                      disabled={listTable.length === 0 || campaignSelected === null || campaignSelected === undefined || idIntencaoCompra === undefined}>
                      Enviar para Fábrica
                    </Button>
                  </div>
                  {(listTable.length === 0 || campaignSelected === null || campaignSelected === undefined) &&
                    <ReactTooltip id="enviar_pedido" place="top" backgroundColor={'#DC3444'} border >
                      <span>Selecione Uma Campanha!</span>
                    </ReactTooltip>
                  }
                </Col>
              </Row>
            </TitleGroupComponent>
          </div>

          <div className="mx-3">
            <>
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex justify-content-start align-items-end">
                      <div className="mx-1" style={{ width: 300, zIndex: 100 }}>
                        <Form.Label>Filial:</Form.Label>
                        <Select
                          value={branchSelected}
                          options={branchOptions}
                          onChange={(val) => {
                            updateListTable([])
                            updateIdIntencaoCompra(undefined)
                            updateCampaignSelected(undefined)
                            updateBranchSelected(val)
                          }}
                          isDisabled={branchOptions === undefined}
                          placeholder="Selecione a Filial"
                        />
                      </div>
                      <div className="mx-1" style={{ width: 300, zIndex: 100 }}>
                        <Form.Label>Campanha:</Form.Label>
                        <Select
                          key={branchSelected?.value}
                          value={campaignSelected}
                          options={campaignsOptions}
                          onChange={(val) =>
                            updateCampaignSelected(val!)
                          }
                          isDisabled={branchSelected === undefined && campaignsOptions === undefined}
                          placeholder="Selecione a campanha"
                        />
                      </div>
                    </div>
                  </div>
                  {listTable.length > 0 ? (
                    <>
                      <div className="mt-3">
                        <ActiveCampaignTotalsCard activeCampaignTotals={activeCampaigntotals!} />
                        <Row md={"auto"}>
                          <Col className="d-flex align-items-center justify-content-between">
                            <Button variant="outline-primary" onClick={showColumn} size={'sm'} className="d-flex align-items-center">
                              <BiColumns />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <div className="wrapper">
                          <div style={{ overflow: "auto", height: "500px", font: "Open Sans", fontSize: "12px", cursor: "pointer" }}>
                            <Table>
                              <thead>
                                <tr className="m-0 p-0">
                                  {columns.map((value, index) => {
                                    freeze = value.order <= 3 ? true : false;

                                    if (freeze && value.order === 1) freezeCss = firstColHeader;
                                    else if (freeze && value.order === 2) freezeCss = secondColHeader;
                                    else if (freeze && value.order === 3) freezeCss = thirdColHeader;
                                    else freezeCss = '';

                                    let align = value.align;

                                    let classNameDiv = `d-flex align-items-center justify-content-${align} mx-2`;
                                    if (align === 'left') classNameDiv = `d-flex align-items-center justify-content-start mx-2`;
                                    if (align === 'right') classNameDiv = `d-flex align-items-center justify-content-end mx-2`;

                                    if (value.hidden === false) {
                                      return (
                                        <>
                                          <th
                                            align={value.align}
                                            className={freeze ? `px-1 th sticky-col ${freezeCss}` : 'px-1'}
                                            key={value.text + "_" + index}
                                            onClick={() => {
                                              orderValueColums(value, LOCALSTORAGE_COLUMS_ORDER, columns, totalPageSize, handleTable, nextPagination);
                                            }}>
                                            <div className={classNameDiv}>
                                              <div className="d-flex align-items-center justify-content-center">
                                                <p
                                                  style={{
                                                    whiteSpace: 'nowrap',
                                                  }}
                                                  className="m-1">
                                                  {value.text}
                                                </p>
                                              </div>
                                            </div>
                                          </th>
                                        </>
                                      );
                                    }
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {listTable.map((value, index) => {
                                  return (
                                    <>
                                      <tr key={index} className="text-center" style={{ background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF' }}>
                                        {columns.map((vc, i) => {
                                          freezeBody = vc.order <= 3 ? true : false;

                                          if (freezeBody && vc.order === 1) freezeCss = firstCol;
                                          else if (freezeBody && vc.order === 2) freezeCss = secondCol;
                                          else if (freezeBody && vc.order === 3) freezeCss = thirdCol;
                                          else freezeCss = '';

                                          if (vc.dataField === 'aacStrDeltaLegenda') {

                                            let deltaIcon: React.ReactElement = (<></>)
                                            if (value['aacStrDeltaLegenda'] === 'verde') {
                                              deltaIcon = (
                                                <div>
                                                  <FaArrowUp size={15} color={'#28A745'} />
                                                </div>
                                              )
                                            }

                                            if (value['aacStrDeltaLegenda'] === 'amarelo') {
                                              deltaIcon = (
                                                <FaArrowUp size={15} color={'#f2e941'} />
                                              )
                                            }

                                            if (value['aacStrDeltaLegenda'] === 'cinza') {
                                              deltaIcon = (
                                                <FaArrowsAltH size={15} color={'#d8d8d8'} />
                                              )
                                            }

                                            if (value['aacStrDeltaLegenda'] === 'vermelho') {
                                              deltaIcon = (
                                                <FaArrowDown size={15} color={'#DC3444'} />
                                              )
                                            }
                                            return (
                                              <td
                                                key={value['id'] + "_" + i}
                                                className={freezeBody ? `m-0 p-0 sticky-col ${freezeCss}` : 'm-0 p-0'}
                                                style={{ background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF' }}
                                              >
                                                <div className="d-flex align-items-center justify-content-center m-0 p-0  my-1">
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    {deltaIcon}
                                                  </div>
                                                </div>
                                              </td>
                                            )
                                          }

                                          if (vc.dataField === 'ALERTA') {
                                            const sizeIcon = 15;

                                            const elements: React.ReactElement[] = [
                                              <div key={value['idPacoteItem'] + "_1"}>
                                                <div data-tip data-for="saldo">
                                                  <Button
                                                    data-tip
                                                    variant="secondary"
                                                    size="sm"
                                                    className="m-0 p-0 bg-white border-0"
                                                    onClick={() => {
                                                      showIcon();
                                                      updateTypeModalIcon('Saldo');
                                                      updateDataRowModalIcon(value);
                                                    }}>
                                                    <MdMapsHomeWork size={sizeIcon} color="#017BFE" />
                                                  </Button>
                                                </div>
                                                <ReactTooltip id="saldo" backgroundColor={'#017BFE'} border>
                                                  <span>Saldo em outras lojas</span>
                                                </ReactTooltip>
                                              </div>,
                                              <div key={value['idPacoteItem'] + "_2"}>
                                                <div data-tip data-for="historico">
                                                  <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="m-0 p-0 bg-white border-0"
                                                    onClick={() => {
                                                      showIcon();
                                                      updateTypeModalIcon('Histórico');
                                                      updateDataRowModalIcon(value);
                                                    }}>
                                                    <MdAttachMoney size={sizeIcon} color="#017BFE" />
                                                  </Button>
                                                </div>
                                                <ReactTooltip
                                                  id="historico"
                                                  type="warning"
                                                  backgroundColor={'#017BFE'}
                                                  border>
                                                  <span>Histórico de preço</span>
                                                </ReactTooltip>
                                              </div>,
                                              <div key={value['idPacoteItem'] + "_3"}>
                                                <div data-tip data-for="correlatos">
                                                  <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="m-0 p-0 bg-white border-0"
                                                    onClick={() => {
                                                      showIcon();
                                                      updateTypeModalIcon('Correlatos');
                                                      updateDataRowModalIcon(value);
                                                    }}>
                                                    <TbSitemap size={sizeIcon} color="#DC3444" />
                                                  </Button>
                                                </div>
                                                <ReactTooltip
                                                  id="correlatos"
                                                  type="warning"
                                                  backgroundColor={'#DC3444'}
                                                  border>
                                                  <span>Items correlatos</span>
                                                </ReactTooltip>
                                              </div>,
                                            ];

                                            if (value['observacao'] !== null) {
                                              if (value['observacao'] === 1) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_4"}>
                                                    <div data-tip data-for="observacao1">
                                                      <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Observacao');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <FiAlertTriangle size={sizeIcon} color="#017BFE" />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="observacao1"
                                                      type="warning"
                                                      backgroundColor={'#017BFE'}
                                                      border>
                                                      <span>Infomação</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                              if (value['observacao'] === 2) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_4"}>
                                                    <div data-tip data-for="observacao2">
                                                      <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Observacao');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <GrCircleInformation size={sizeIcon} color="#017BFE" />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="observacao2"
                                                      type="warning"
                                                      backgroundColor={'#017BFE'}
                                                      border>
                                                      <span>Alerta</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                              if (value['observacao'] === 3) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_4"}>
                                                    <div data-tip data-for="observacao3">
                                                      <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Observacao');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <GrStatusCritical size={sizeIcon} color="#017BFE" />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="observacao3"
                                                      type="warning"
                                                      backgroundColor={'#017BFE'}
                                                      border>
                                                      <span>Crítico</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                              if (value['observacao'] === 4) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_4"}>
                                                    <div data-tip data-for="observacao4">
                                                      <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Observacao');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <BsTag size={sizeIcon} color="#017BFE" />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="observacao4"
                                                      type="warning"
                                                      backgroundColor={'#017BFE'}
                                                      border>
                                                      <span>Oferta Aberta</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                              if (value['observacao'] === 5) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_4"}>
                                                    <div data-tip data-for="observacao5">
                                                      <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Observacao');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <BsFillCartFill size={sizeIcon} color="#017BFE" />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="observacao5"
                                                      type="warning"
                                                      backgroundColor={'#017BFE'}
                                                      border>
                                                      <span>Oferta Pedido</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                              if (value['observacao'] === 6) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_4"}>
                                                    <div data-tip data-for="observacao6">
                                                      <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Observacao');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <HiBell size={sizeIcon} color="#017BFE" />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="observacao6"
                                                      type="warning"
                                                      backgroundColor={'#017BFE'}
                                                      border>
                                                      <span>Alerta Automático</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                            }

                                            if (value['percentualDesconto'] > 0) {
                                              elements.push(
                                                <div key={value['idPacoteItem'] + "_5"}>
                                                  <div data-tip data-for="desconto">
                                                    <Button
                                                      variant="secondary"
                                                      size="sm"
                                                      className="m-0 p-0 bg-white border-0"
                                                      onClick={() => {
                                                        showIcon();
                                                        updateTypeModalIcon('Desconto');
                                                        updateDataRowModalIcon(value);
                                                      }}>
                                                      <BsPercent size={sizeIcon} color="#017BFE" />
                                                    </Button>
                                                  </div>
                                                  <ReactTooltip
                                                    id="desconto"
                                                    type="warning"
                                                    backgroundColor={'#017BFE'}
                                                    border>
                                                    <span>Desconto</span>
                                                  </ReactTooltip>
                                                </div>
                                              );
                                            }

                                            if (
                                              value['curva'] === 'D1' ||
                                              value['curva'] === 'D2' ||
                                              value['curva'] === 'D3' ||
                                              value['curva'] === 'D4' ||
                                              value['curva'] === 'N1' ||
                                              value['curva'] === 'N2' ||
                                              value['curva'] === 'N3' ||
                                              value['curva'] === 'N4'
                                            ) {
                                              if (loggedUserDataRole.includes('IS_OBSOLETOS')) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_6"}>
                                                    <div data-tip data-for="venda">
                                                      <Button
                                                        size="sm"
                                                        variant="secondary"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Venda');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <FaArrowRight size={sizeIcon} color={'#FFC007'} />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="venda"
                                                      type="warning"
                                                      backgroundColor={'#FFC007'}
                                                      border>
                                                      <span>Venda</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                            } else if (
                                              value['curva'] === 'A1' ||
                                              value['curva'] === 'A2' ||
                                              value['curva'] === 'A3' ||
                                              value['curva'] === 'A4' ||
                                              value['curva'] === 'B1' ||
                                              value['curva'] === 'B2' ||
                                              value['curva'] === 'B3' ||
                                              value['curva'] === 'B4' ||
                                              value['curva'] === 'C1' ||
                                              value['curva'] === 'C2' ||
                                              value['curva'] === 'C3' ||
                                              value['curva'] === 'C4'
                                            ) {
                                              if (loggedUserDataRole.includes('IS_OBSOLETOS')) {
                                                elements.push(
                                                  <div key={value['idPacoteItem'] + "_6"}>
                                                    <div data-tip data-for="compra">
                                                      <Button
                                                        size="sm"
                                                        variant="secondary"
                                                        className="m-0 p-0 bg-white border-0"
                                                        onClick={() => {
                                                          showIcon();
                                                          updateTypeModalIcon('Compra');
                                                          updateDataRowModalIcon(value);
                                                        }}>
                                                        <FaArrowLeft size={sizeIcon} color={'#FFC007'} />
                                                      </Button>
                                                    </div>
                                                    <ReactTooltip
                                                      id="compra"
                                                      type="warning"
                                                      backgroundColor={'#28A745'}
                                                      border>
                                                      <span>Compra</span>
                                                    </ReactTooltip>
                                                  </div>
                                                );
                                              }
                                            }

                                            return (
                                              <td
                                                key={value['idPacoteItem'] + "_7"}
                                                className={freezeBody ? `m-0 p-0 sticky-col ${freezeCss}` : 'm-0 p-0'}
                                                style={{ background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF' }}
                                              >
                                                <div className="d-flex align-items-center justify-content-start m-0 p-0  my-1">
                                                  <div key={value['idPacoteItem'] + "_8"} className="d-flex align-items-center justify-content-start">
                                                    {elements}
                                                  </div>
                                                </div>
                                              </td>
                                            );
                                          }

                                          if (vc.dataField === 'qtdPendente') {
                                            let borderWidth = value['alterado'] ? 3 : 1;
                                            let borderColor = value['alterado'] ? '#32B529' : '';

                                            return (
                                              <td
                                                key={value['qtdPendente'] + "_" + value['id']}
                                                className={freezeBody ? `m-0 p-0 mx-2 sticky-col ${freezeCss}` : 'm-0 p-0 mx-2'}
                                                style={{ background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF' }}
                                              >
                                                <div className="d-flex align-items-center m-0 p-0 mx-2">
                                                  <div>
                                                    <Form.Control
                                                      disabled={value['bloquadoCompra']}
                                                      className={`m-0 p-0 mx-2`}
                                                      type="number"
                                                      min="0"
                                                      defaultValue={value['qtdPendente']}
                                                      size="sm"
                                                      style={{
                                                        width: 50,
                                                        borderWidth: borderWidth,
                                                        borderColor: borderColor,
                                                      }}
                                                      onBlur={handleInputBlurQuantity(value)}
                                                      onFocus={handleOnFocusQuantity()}
                                                      onKeyDown={(evt) =>
                                                        (evt.key === 'e' || evt.key === '-' || evt.key === '.' || evt.key === ',' || evt.key === "'") &&
                                                        evt.preventDefault()
                                                      }
                                                    />
                                                  </div>
                                                  {value['bloquadoCompra'] && (
                                                    <>
                                                      <AiFillLock data-tip data-for="bloqueado" size={18} className="m-1 ms-2" color={'#6C757D'} />
                                                      <ReactTooltip id="bloqueado" backgroundColor={'#6C757D'} border>
                                                        <span>Item bloqueado para compra</span>
                                                      </ReactTooltip>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                            );
                                          }

                                          if (vc.hidden === false) {
                                            if (vc.dataField === 'percentualDesconto' && value[vc.dataField] === null) {
                                              return (
                                                <td
                                                  key={value["percentualDesconto"] + value["id"] + "_" + i}
                                                  className={freezeBody ? `m-0 p-0 mx-2 sticky-col ${freezeCss}` : 'm-0 p-0 mx-2'}
                                                  style={{
                                                    textAlign: vc.align,
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 250,
                                                    background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF',
                                                  }}>
                                                  <div className="m-0 p-0 mx-3 my-1">0 %</div>
                                                </td>
                                              );
                                            }

                                            if (value[vc.dataField] === null || value[vc.dataField] === undefined) {
                                              return (
                                                <td
                                                  key={vc.dataField + value["id"] + "_" + i}
                                                  className={freezeBody ? `m-0 p-0 mx-2 sticky-col ${freezeCss}` : 'm-0 p-0 mx-2'}
                                                  style={{
                                                    textAlign: 'center',
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 250,
                                                    background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF',
                                                  }}>
                                                  <div className="m-0 p-0 mx-3 my-1">{''}</div>
                                                </td>
                                              );
                                            }

                                            if (typeof value[vc.dataField] === 'number') {
                                              let formatted = value[vc.dataField];
                                              if (vc.numberFormat === 'currency') {
                                                formatted = Intl.NumberFormat('pt-BR', {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                }).format(formatted);
                                              }

                                              if (vc.numberFormat === 'percent') {
                                                formatted = `${formatted}%`;
                                              }

                                              if (vc.typeField === 'monetary' && formatted !== undefined) {
                                                formatted = formatted.toLocaleString('pt-br', {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                });
                                              }

                                              return (
                                                <td
                                                  key={value[vc.dataField] + value["id"] + "_" + i}
                                                  className={freezeBody ? `m-0 p-0 mx-2 sticky-col ${freezeCss}` : 'm-0 p-0 mx-2'}
                                                  style={{
                                                    textAlign: vc.align,
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 250,
                                                    background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF',
                                                  }}>
                                                  <div className="m-0 p-0 mx-3 my-1">{formatted}</div>
                                                </td>
                                              );
                                            }

                                            if (typeof value[vc.dataField] === 'string') {
                                              let formatted = value[vc.dataField];
                                              if (vc.typeField === 'monetary') {
                                                formatted = formatted.toLocaleString('pt-br', {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                });
                                              }

                                              if (vc.typeField === 'dateTime') {
                                                if (formatted === null) {
                                                  formatted = 'N/A';
                                                } else {
                                                  formatted = ParseDateToBrV2(formatted);
                                                }
                                              }

                                              return (
                                                <td
                                                  key={value[vc.dataField] + value["id"] + "_" + i}
                                                  className={freezeBody ? `m-0 p-0 mx-2 sticky-col ${freezeCss}` : 'm-0 p-0 mx-2'}
                                                  style={{
                                                    textAlign: vc.align,
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 250,
                                                    background: value['checked'] === true ? '#F2F2F2' : '#FFFFFF',
                                                  }}>
                                                  <div className="m-0 p-0 mx-3 my-1">{formatted}</div>
                                                </td>
                                              );
                                            }
                                          }
                                        })}
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <Row className="d-flex align-items-start justify-content-between">
                          <Col className="d-flex justify-content-start"></Col>
                          <Col>
                            <div className="d-flex justify-content-center">
                              <Pagination size="sm">
                                <Pagination.First onClick={onClickFirstPagination}>Primeira</Pagination.First>
                                <Pagination.Prev onClick={onClickPrevPagination}>Anterior</Pagination.Prev>
                                {paginationItemList?.map((value, key) => {
                                  return (
                                    <Pagination.Item key={key} active={value.active} onClick={() => onClickItemPagination(value)}>
                                      {value.item + 1}
                                    </Pagination.Item>
                                  );
                                })}
                                <Pagination.Next onClick={onClickNextPagination}>Próxima</Pagination.Next>
                                <Pagination.Last onClick={onClickLastPagination}>Última</Pagination.Last>
                              </Pagination>
                            </div>
                          </Col>
                          <Col className="d-flex justify-content-end">
                            <Button
                              variant="outline-danger"
                              onClick={() => { removeQuantities() }}
                              size={'sm'}>
                              Zerar Valores Salvos
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-center" style={{ height: '60vh' }}>
                        <p className="m-5">A busca não retorna nada, verifique o Planejamento ou clique em Processar</p>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </>
          </div>
          <Footer />

        </div>
      )}

      <ModalOrderHideColumns totalPageSize={totalPageSize} columns={columns} showModalColumns={showModalColumns} closeColumn={closeColumn}
        applyAction={async () => {
          closeColumn();
          handleOrderHideColumns(columns);
          changeValueListByColums(listTable);
          await handleTable();
        }} />
      <ModalPlanning applyAction={handleApplyPlanning}
        closePlanning={closePlanning}
        createPlanning={createPlanning}
        editPlanning={editPlanning}
        removePlanning={removePlanning}
        keyPlanningLocalStorage={LOCALSTORAGE_PLANNING}
        listAllPlanning={listAllPlanning}
        namePlanningLocalStorage={LOCALSTORAGE_PLANNING}
        optionsFiltersDefault={optionsFiltersDefault}
        showModalPlanning={showModalPlanning} />
      <ModalIcon
        closeIcon={closeIcon}
        dataModalIcon={dataModalIcon}
        showModalIcon={showModalIcon}
        typeModalIcon={typeModalIcon} />
      <ModalConfirmAction
        confirmActionFunction={confirmSendOrder}
        confirmButtonMessage="Enviar"
        closeButtonMessage="Cancelar"
        closeModalConfirmAction={closeModalConfirmAction}
        message="Confirmar o envio do pedido"
        showModalConfirmAction={showModalConfirmAction}
        titleConfirmAction="Enviar Pedido" />
    </>
  )
}