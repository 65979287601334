/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { BsTrash } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { UseAlertModal } from 'hook/alert-modal-hook';
import { FiltersDefault } from '../../@types/interfaces/filter-interface';
import { Button, Card, Col, DropdownButton, Form, Modal, Row, Table } from 'react-bootstrap';
import { RestUseCase } from 'service/api/rest/rest-usecase';
import { getEnv } from 'helper/window-helper';
import { updateLocalStorege } from '../../data/cache/localstorage-cache';
import { SelectOptionInterface } from '../../@types/entity/interface/select-option-interface';

interface ItemFilter {
  type: string;
  params: any;
}

interface CreateField {
  field: any;
  indexSelected: number;
}

type Props = {
  filtersDefault: FiltersDefault[];
  apply: (params?: any) => any;
  listAllPlanning: (params?: any) => Promise<any>;
  listPlanningByCompany?: (params?: any) => Promise<any>;
  listByIdPlanning: (params?: any) => Promise<any>;
  createPlanning: (params?: any) => Promise<any>;
  editPlanning: (params?: { model: any; id: number }) => Promise<any>;
  removePlanning: (params?: any) => Promise<any>;
  onChangeModalPlanning?: (params: any) => void;
  filterLocalStorage?: any[];
  keyLocalStorage?: string;
  isOnlineConsultant: boolean;
};

const ContainerFilter: React.FC<Props> = ({
  apply,
  filtersDefault,
  listAllPlanning,
  listPlanningByCompany,
  createPlanning,
  removePlanning,
  editPlanning,
  onChangeModalPlanning,
  filterLocalStorage,
  keyLocalStorage,
  isOnlineConsultant
}: Props) => {
  const [modelItemFilter, updateItemFilter] = useState<ItemFilter[]>([]);
  const [listPlanning, updateListPlanning] = useState<any[]>([]);
  const [namePlanning, updateNamePlanning] = useState<string>('');
  const [idPlanning, updateIdPlanning] = useState<number | undefined>(undefined);
  const [actualPage, updateActualPage] = useState<string>('');


  /** ALERTA */
  const { messageAlertModal, showAlertModal } = UseAlertModal();

  function AlertModal() {
    if (messageAlertModal?.show) {
      return (
        <div className={'alert alert-' + messageAlertModal.color + ' p-1 m-0 ps-4 pe-4 d-flex align-items-center'} role="alert">
          {messageAlertModal.content}
          {!messageAlertModal.time && (
            <button
              type="button"
              className="ms-1 btn-close btn-sm"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() =>
                showAlertModal({
                  show: false,
                  content: '',
                  color: '',
                })
              }></button>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  const handleChangeState = () => {
    if (onChangeModalPlanning !== null && onChangeModalPlanning !== undefined) {
      onChangeModalPlanning!({ state: "New Some State from Child" });  
    }
  };

  /** chamadas */
  async function handleListAllPlanning() {
    await listAllPlanning()
      .then((response) => {
        if (response.statusCode === 200) {
          updateListPlanning(response.body);
        }
      })
      .catch(() =>
        showAlertModal({
          show: true,
          content: 'Erro ao Listar Planejamento',
          color: 'danger',
          time: 2000,
        })
      );
  }

  async function handleListPlanningByCompany() {
    if (listPlanningByCompany !== null && listPlanningByCompany !== undefined) {
      await listPlanningByCompany()
        .then((response) => {
          if (response.statusCode === 200) {
            updateItemsPlanningByCompany(response.body)
          }
        })
        .catch(() =>
          showAlertModal({
            show: true,
            content: 'Erro ao Listar Planejamento',
            color: 'danger',
            time: 2000,
          })
        );
    }
  }

  const applySavedFilter = (params: any) => {
    updateNamePlanning(params.nome);
    localStorage.setItem('planning_name_' + actualPage, params.nome);
    apply(params.filtros.filter((e: { type: null | string }) => e.type !== null && e.type.length > 0));
  };
  const buildEditSavedFilter = (params: any) => {
    updateIdPlanning(params.id);
    updateNamePlanning(params.nome);
    updateItemFilter(params.filtros);
  };
  const editFilter = async () => {
    if (namePlanning === '') {
      showAlertModal({
        show: true,
        content: 'Preencha o nome do planejamento',
        color: 'danger',
        time: 2000,
      });
      return;
    }

    if (modelItemFilter.length > 0) {
      await editPlanning({
        model: { nome: namePlanning, filtros: modelItemFilter },
        id: idPlanning!,
      })
        .then((response) => {
          if (response.statusCode !== 500) {
            updateListPlanning(response.body);
            handleChangeState()
            showAlertModal({
              show: true,
              content: 'Planejamento editado com sucesso',
              color: 'success',
              time: 2000,
            });
          }

          updateNamePlanning(response.nome);
          updateLocalStorege('planning_name_' + actualPage, response.nome);
        })
        .catch(() =>
          showAlertModal({
            show: true,
            content: 'Erro ao Editar',
            color: 'danger',
            time: 2000,
          })
        );

      await handleListAllPlanning();
    } else {
      showAlertModal({
        show: true,
        content: 'Escolha pelo menos 1 planejamento',
        color: 'danger',
        time: 2000,
      });
      return;
    }
  };
  const deleteSavedFilter = async (params: any) => {
    await removePlanning(params.id)
      .then(() => {
        localStorage.removeItem('planning_name_' + actualPage);
        updateNamePlanning("");
        handleChangeState()
        showAlertModal({
          show: true,
          content: 'Planejamento deletado com sucesso',
          color: 'success',
          time: 2000,
        });
      })
      .catch(() =>
        showAlertModal({
          show: true,
          content: 'Erro ao Deletar Planejamento',
          color: 'danger',
          time: 2000,
        })
      );

    await handleListAllPlanning();
  };

  const montaPlanejamento = (planejamentoParam: ItemFilter[]) => {
    let modifiedList = planejamentoParam.map((item: ItemFilter) => {
      let fieldName = Object.keys(item.params)[1];
      if (item.params.type === 'GREATER' || item.params.type === 'LESSER') {
        if (getFilterByType(item.type)?.field[1].typeInput === 'multi-select') {

          if (item.type === 'PONTUACAO_ITEM') {
            if (item.params.pontuacoes.length === undefined) {
              item.params.pontuacoes = Number(item.params.pontuacoes);
            } else {
              item.params.pontuacoes = Number(item.params.pontuacoes[0]);
            }
          } else {
            item.params[fieldName] = item.params[fieldName][0];
          }
        } else {
          if (item.type === 'MESES_ULTIMA_COMPRA') {
            item.params.meses_ultima_compra = Number(item.params.meses_ultima_compra);
          }

          if (item.type === 'MESES_ULTIMA_VENDA') {
            item.params.meses_ultima_venda = Number(item.params.meses_ultima_venda);
          }
        }
      }
      if (item.params.type === 'IN' || item.params.type === 'NOT_IN') {
        if (item.type === 'MESES_ULTIMA_COMPRA') {
          item.params.meses_ultima_compra = item.params.meses_ultima_compra.map((value: any) => {
            value = Number(value);
            return value;
          });
        }
        if (item.type === 'MESES_ULTIMA_VENDA') {
          item.params.meses_ultima_venda = item.params.meses_ultima_venda.map((value: any) => {
            value = Number(value);
            return value;
          });
        }
        // if (item.type === 'PONTUACAO_ITEM') {
        //   item.params.pontuacoes = item.params.pontuacoes.map((value: any) => {
        //     value = Number(value);
        //     return value;
        //   });
        // }
      }
      return item;
    });

    return modifiedList;
  };

  const saveFilter = async () => {
    if (namePlanning === '') {
      showAlertModal({
        show: true,
        content: 'Preencha o nome do planejamento',
        color: 'danger',
        time: 2000,
      });
      return;
    }

    if (modelItemFilter.length > 0) {

      const modifiedListsaveFilter = montaPlanejamento(modelItemFilter);

      var response = await createPlanning({
        nome: namePlanning,
        filtros: modifiedListsaveFilter.filter((e: { type: null | string }) => e.type !== null && e.type.length > 0),
      });
      if (response.statusCode === 200 || response.statusCode === 201 || response.statusCode === 204) {
        updateListPlanning(response.body);
        handleChangeState()
        showAlertModal({
          show: true,
          content: 'Planejamento salvo com sucesso',
          color: 'success',
          time: 2000,
        });

        updateNamePlanning(namePlanning);
        localStorage.setItem('planning_name_' + actualPage, namePlanning);
      } else
        showAlertModal({
          show: true,
          content: 'Erro ao Salvar',
          color: 'danger',
          time: 2000,
        });

      await handleListAllPlanning();
    } else {
      showAlertModal({
        show: true,
        content: 'Escolha pelo menos 1 planejamento',
        color: 'danger',
        time: 2000,
      });
      return;
    }
  };
  const applyFilter = () => {
    let send: Boolean = false;
    if (modelItemFilter.length === 0) {
      apply(
        modelItemFilter.filter((item) => {
          if (!objetoVazio(item.params)) {
            return item;
          }
        })
      );
    } else {
      modelItemFilter.filter((item) => {
        if (objetoVazio(item.params)) {
          send = false;
        } else {
          if (getFilterByType(item.type)?.field.length! === 3) {
            if (Object.keys(item.params).length >= 2) {
              send = true;
            } else {
              send = false;
            }
          } else {
            if (Object.keys(item.params).length === getFilterByType(item.type)?.field.length) {
              send = true;
            } else {
              send = false;
            }
          }
        }
      });
    }

    if (send === true) {
      const modifiedListapplyFilter = montaPlanejamento(modelItemFilter);

      apply(
        modifiedListapplyFilter.filter((item) => {
          if (!objetoVazio(item.params)) {
            return item;
          }
        })
      );
    } else {
      showAlertModal({
        show: true,
        content: 'Existe um ou mais planejamentos, que estão com configurações inválidas',
        color: 'danger',
        time: 3000,
      });
      return;
    }
  };

  function objetoVazio(obj: { hasOwnProperty: (arg0: string) => any }) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  /** useEffect */
  useEffect(() => {
    handleListAllPlanning();
    handleListPlanningByCompany();
    if (filterLocalStorage) {
      updateItemFilter(filterLocalStorage);
    }

    updateActualPage(localStorage.getItem('actual_page') || '');
    const storedPlanningName = localStorage.getItem('planning_name_' + localStorage.getItem('actual_page'));
    if (storedPlanningName != null) {
      updateNamePlanning(storedPlanningName);
    }
  }, []);

  useEffect(() => {
    if (messageAlertModal?.time)
      setTimeout(() => {
        showAlertModal({
          show: false,
          content: '',
          color: '',
        });
      }, messageAlertModal.time);
  }, [messageAlertModal, showAlertModal]);

  /** filtros */
  const clearFilter = () => {
    updateIdPlanning(undefined);
    updateNamePlanning('');
    updateItemFilter([]);
    localStorage.removeItem('planning_name_' + actualPage);
  };

  const removeItem = (params: any) => {
    updateItemFilter(
      modelItemFilter.filter((value, index) => {
        if (index !== params) return value;
      })
    );
  };

  const edit = (item: FiltersDefault, indexSelected: number) => {
    updateItemFilter(
      modelItemFilter.map((value, index) => {
        if (index === indexSelected) {
          value.type = item.typeFilter;
          value.params = {};
        }
        return value;
      })
    );
  };

  const add = (item: FiltersDefault) => {
    updateItemFilter([
      ...modelItemFilter,
      {
        type: item.typeFilter,
        params: {},
      },
    ]);
  };

  const updateParameterValue = (index: number, param: string, value: any) => {
    updateItemFilter(
      modelItemFilter.map((item, itemIndex) => {
        if (itemIndex === index) {
          item.params[param!] = value;
        }
        return item;
      })
    );
  };

  function getParameterValueOption(options: Array<any>, index: number, param: string) {
    const currentValue = modelItemFilter[index]!.params[param];
    return options.find((option) => option.value === currentValue);
  }

  function getFilterByType(type: string) {
    const filtered = filtersDefault.filter((value) => value.typeFilter === type);
    return filtered.length > 0 ? filtered[0] : null;
  }

  const filterOptions = filtersDefault.map((option) => {
    return { value: option.typeFilter, label: option.label };
  });

  const ENDPOINT_FILIAL_OBSOLETO = getEnv('REACT_APP_END_POINT_FILIAL_OBSOLETO')!;

  const handleObsoletos = async (id: number) => {
    return await new RestUseCase(`${ENDPOINT_FILIAL_OBSOLETO}?idEmpresa=${id}`).Get().then((response) => {
      return response.body.map((value: any) => {
        return { value: value.id, label: value.nome };
      });
    });
  };

  function isNumber(n: string | number) {
    return !isNaN(parseFloat(String(n))) && isFinite(Number(n));
  }

  function verifySelectField(filter: any, event: any, params: CreateField, fieldLabel: string, fieldName: any) {
    if (filter?.label.toLowerCase() === fieldLabel) {
      if (event.value === 'GREATER' || event.value === 'LESSER') {
        if (params.field.params[fieldName] !== undefined) {
          params.field.params[fieldName] = [params.field.params[fieldName][0]];
        }
      }
    }
  }

  function verifyFieldAcceptComma(value: any, e: any): string {
    var changedValue;
    if (value.name === 'custo_medio' || value.name === 'media_fixa') {
      changedValue = e.target.value?.replace(',', '.');
    } else {
      changedValue = e.target.value?.replace(/,/g, '');
      changedValue = changedValue?.replace('.', '');
    }
    return changedValue;
  }

  /** Cria filtro */
  function FieldCreated(params: CreateField) {
    let filter = getFilterByType(params.field.type);
    const elements: React.ReactElement[] = [];
    const [filteredList, updatefilteredList] = useState<any[]>([]);

    filter?.field.forEach((value: any, index: number) => {
      const currentValue = modelItemFilter[params.indexSelected]!.params[value.name];

      if (value.typeInput === 'select' || value.typeInput === 'checkbox') {
        elements.push(
          <Col md={4} key={index}>
            <Form.Label>{value.name === 'type' ? 'Operador:' : 'Selecione um valor:'}</Form.Label>
            {currentValue || typeof currentValue === 'boolean' ? (
              <Select
                options={value.optionsInput}
                onChange={async (e: any) => {
                  if (filter?.label.toLowerCase() === 'obsoleto' && value.name === 'empresa') {
                    filter.field[2].optionsInput = [];
                    let dados = await handleObsoletos(Number(e.value));
                    filter.field[2].optionsInput = dados;
                  }

                  // verifySelectField(filter, e, params, 'pontuação do item', 'pontuacoes');
                  verifySelectField(filter, e, params, 'meses da última compra', 'meses_ultima_compra');
                  verifySelectField(filter, e, params, 'meses da última venda', 'meses_ultima_venda');
                  verifySelectField(filter, e, params, 'meses da última compra do fornecedor', 'meses_ultima_compra');

                  updateParameterValue(params.indexSelected, value.name, e.value);
                }}
                defaultValue={() => {
                  const currentValue = modelItemFilter[params.indexSelected]!.params[value.name];
                  if (value.optionsInput) {
                    return value.optionsInput.find((opt: { label: string; value: any }) => opt.value === currentValue) ?? value.optionsInput[0];
                  } else {
                    return true;
                  }
                }}
              />
            ) : (
              <Select
                options={value.optionsInput}
                onChange={async (e: any) => {
                  if (filter?.label.toLowerCase() === 'obsoleto' && value.name === 'empresa') {
                    filter.field[2].optionsInput = [];
                    let dados = await handleObsoletos(Number(e.value));
                    filter.field[2].optionsInput = dados;
                  }

                  updateParameterValue(params.indexSelected, value.name, e.value);
                }}
              />
            )}
          </Col>
        );
      }

      if (value.typeInput === 'multi-select' && (value.inputLettersDelay === null || value.inputLettersDelay === undefined)) {
        elements.push(
          <Col md={4} key={index}>
            <Form.Label>{value.name === 'type' ? 'Operador:' : 'Selecione o(s) valor(es):'}</Form.Label>
            <Select
              options={value.optionsInput}
              isMulti={true}
              onChange={(e: any) => {
                if (
                  e.length > 1 &&
                  (params.field.params.type === 'GREATER' || params.field.params.type === 'LESSER' || params.field.params.type === 'NOT_EQUALS')
                ) {
                  e = [e[e.length - 1]];
                }
                updateParameterValue(
                  params.indexSelected,
                  value.name,
                  e.map((elements: any) => elements.value)
                );
              }}
              defaultValue={() => {
                const currentValue = modelItemFilter[params.indexSelected]?.params[value.name] ?? [];

                if (currentValue.length === 0) {
                  return '';
                } else {
                  return Array.isArray(currentValue)
                    ? value.optionsInput.filter((opt: { label: string; value: any }) => currentValue.includes(opt?.value))
                    : value.optionsInput.filter((opt: { label: string; value: any }) => currentValue === opt?.value);
                }
              }}
            />
          </Col>
        );
      }

      if (value.typeInput === 'multi-select' && value.inputLettersDelay !== null && value.inputLettersDelay !== undefined) {
        elements.push(
          <Col md={4} key={index}>
            <Form.Label>{value.name === 'type' ? 'Operador:' : 'Selecione o(s) valor(es):'}</Form.Label>
            <Select
              options={filteredList}
              isMulti={true}
              placeholder={`Digite ${value.inputLettersDelay} letras para filtrar`}
              onInputChange={input => {
                if (input.length >= value.inputLettersDelay) {
                  updatefilteredList(value.optionsInput.filter((value: { label: string }) => value.label.toLowerCase().includes(input)))
                }
              }}
              onChange={(e: any) => {
                if (
                  e.length > 1 &&
                  (params.field.params.type === 'GREATER' || params.field.params.type === 'LESSER' || params.field.params.type === 'NOT_EQUALS')
                ) {
                  e = [e[e.length - 1]];
                }
                updateParameterValue(
                  params.indexSelected,
                  value.name,
                  e.map((elements: any) => elements.value)
                );
              }}
              defaultValue={() => {
                const currentValue = modelItemFilter[params.indexSelected]?.params[value.name] ?? [];

                if (currentValue.length === 0) {
                  return '';
                } else {
                  return Array.isArray(currentValue)
                    ? value.optionsInput.filter((opt: { label: string; value: any }) => currentValue.includes(opt?.value))
                    : value.optionsInput.filter((opt: { label: string; value: any }) => currentValue === opt?.value);
                }
              }}
            />
          </Col>
        );
      }

      if (value.typeInput === 'text' || value.typeInput === 'date') {
        elements.push(
          <Col md={4} key={index}>
            <Form.Label>{value.typeInput === 'date' ? 'Data:' : 'Digite um valor:'}</Form.Label>
            <Form.Control
              type={value.typeInput}
              onBlur={(e: any) => updateParameterValue(params.indexSelected, value.name, e.target.value)}
              defaultValue={modelItemFilter[params.indexSelected]?.params[value.name] ?? ''}
            />
          </Col>
        );
      }
      //todo: colocando tipo de campo text-list, como ja existe no componente de planejamento
      if (value.typeInput === 'text-list') {
        let valor: any = 0;

        if (typeof modelItemFilter[params.indexSelected].params[value.name!] === 'number') {
          valor = modelItemFilter[params.indexSelected].params[value.name!];
        }

        if (typeof modelItemFilter[params.indexSelected].params[value.name!] === 'object') {
          valor = modelItemFilter[params.indexSelected].params[value.name!]?.join(value.separator);
        }
        elements.push(
          <Col md={4} key={index}>
            <Form.Label>
              {params.field.params.type === 'GREATER' || params.field.params.type === 'LESSER'
                ? 'Digite um valor'
                : `Digite um valor (separado por ${value.separator})`}
            </Form.Label>
            <Form.Control
              type={value.typeInput}
              onBlur={(e: any) => {
                let list = e.target.value.split(value.separator).map((item: string) => item.split(/\s+/).join(''));
                updateParameterValue(params.indexSelected, value.name, list);
              }}
              // defaultValue={
              //   modelItemFilter[params.indexSelected].params[
              //     value.name!
              //     ]?.join(value.separator) ?? ''
              // }
              defaultValue={valor}
            />
          </Col>
        );
      }

      if (value.typeInput === 'number') {
        elements.push(
          <Col md={4} key={index}>
            <Form.Label>{value.typeInput === 'date' ? 'Data:' : 'Digite um valor:'}</Form.Label>
            <Form.Control
              type={'text'}
              onChange={(e) => {
                const newValue = verifyFieldAcceptComma(value, e);
                if (value.name === 'quantidade_excesso') {
                  if (Number(newValue) < 0) {
                    e.target.value = '0';
                  }
                }

                if (!isNumber(newValue)) {
                  e.target.value = '';
                }
              }}
              onBlur={(e) => {
                const newValue = verifyFieldAcceptComma(value, e);
                if (isNumber(newValue)) {
                  updateParameterValue(params.indexSelected, value.name, parseFloat(newValue));
                } else {
                  updateParameterValue(params.indexSelected, value.name, 0);
                }
              }}
              defaultValue={modelItemFilter[params.indexSelected]?.params[value.name] ?? ''}
            />
          </Col>
        );
      }
    });

    return <Row className="d-flex justify-content-start">{elements}</Row>;
  }
  const [itemsPlanningByCompany, updateItemsPlanningByCompany] = useState<any[]>([]);
  const [showModalPlanningByCompany, updateModalPlanningByCompany] = useState(false);
  const closePlanningByCompany = () => updateModalPlanningByCompany(false);
  const showPlanningByCompany = () => updateModalPlanningByCompany(true);

  //TODO Seperar esta modal ao juntar com a refatoração de estrutura de projeto
  const ModalPlanningByCompany: React.FC<{ itemsList: any[], selectItem: (params: any) => void, closePlanningByCompany: () => void }> = ({ itemsList, selectItem, closePlanningByCompany }) => {

    const [selectedUserName, updateSelectedUserName] = useState<SelectOptionInterface>();
    const [filteredItemList, updateFilteredItemList] = useState<any[]>(itemsList);
    const [itemsPlanningUserNames, updateItemsPlanningUserNames] = useState<SelectOptionInterface[]>([]);

    useEffect(() => {
      updateFilteredItemList(itemsList)
      let filteredList = itemsList.filter((obj: { nomeUsuario: any }, index: any, self: any[]) =>
        index === self.findIndex((t) => t.nomeUsuario === obj.nomeUsuario)
      )
      updateItemsPlanningUserNames(filteredList.map((value: any) => { return { value: value.nomeUsuario, label: value.nomeUsuario } }))
    }, []);

    return (
      <Modal show={showModalPlanningByCompany} onHide={closePlanningByCompany} size={'xl'} centered={true} >
        <Modal.Header closeButton>
          <Modal.Title className="ms-3 lead" style={{ fontSize: '30px' }}>
            Busca de Planejamentos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Col>
                <Form.Label>Filtrar por Usuario:</Form.Label>
                <Select
                  key={selectedUserName?.label}
                  options={itemsPlanningUserNames}
                  isClearable
                  onChange={(val) => {
                    updateSelectedUserName(val!)
                    if (val == null || val === undefined) {
                      updateFilteredItemList(itemsPlanningByCompany)
                    } else {
                      updateFilteredItemList(itemsPlanningByCompany.filter((value) => value.nomeUsuario === val?.value))
                    }
                  }}
                  value={selectedUserName}
                />
              </Col>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Card>
                <Card.Body>
                  <div className="mt-2">
                    {itemsList.length > 0 ? (
                      <Table size="sm" className="mt-2" style={{ cursor: 'pointer' }}>
                        <thead>
                          <tr>
                            <th>Usuario</th>
                            <th>Nome do planejamento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredItemList.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td>{value.nomeUsuario}</td>
                                <td className="d-flex align-items-center justify-content-between">
                                  <div>{value.nome}</div>
                                  <div>
                                    {!isOnlineConsultant && <Button variant="outline-primary" size="sm" onClick={() => { selectItem(value); closePlanningByCompany() }}>
                                      Selecionar Planejamento
                                    </Button>}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <p className="m-5">Nenhum Filtro Salvo</p>
                        </div>
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <div style={{ height: '35px' }} className="d-flex justify-content-start">
        <AlertModal />
      </div>
      <div style={{ maxHeight: "80vh", height: "80vh", overflowY: "auto", overflowX: "hidden"}}>
        <div className="mt-1">
          <div>
            <h3>
              Filtro: <em>{namePlanning}</em>
            </h3>
          </div>
          <div className="d-flex justify-content-end">
            {listPlanningByCompany &&
              <Button variant="outline-secondary me-1" onClick={showPlanningByCompany} size="sm">
                Buscar Planejamento
              </Button>}
            {!isOnlineConsultant &&
              <Button variant="outline-primary me-1" onClick={applyFilter} size="sm">
                Aplicar Planejamento
              </Button>}
            <DropdownButton variant="primary me-1" title={idPlanning === undefined ? 'Salvar Planejamento' : 'Editar Planejamento'} size="sm" autoClose="outside">
              <div>
                <Form.Control className="mx-3 my-2 w-auto" onBlur={(e) => updateNamePlanning(e.target.value)} placeholder="Nome do planejamento" />
                <Button variant="primary" className="ms-3" onClick={idPlanning === undefined ? saveFilter : editFilter} size="sm">
                  Confirmar
                </Button>
              </div>
            </DropdownButton>
            <div>
              <Button variant="outline-danger" className="ms-4" onClick={clearFilter} size="sm">
                Limpar
              </Button>
            </div>
          </div>
          {modelItemFilter.map((value, index) => {
            const optionSelected = getFilterByType(value.type);
            const valueSelect = {
              value: optionSelected!.typeFilter,
              label: optionSelected!.label,
            };

            return (
              <div className="mt-1" key={index}>
                <Row>
                  <Col md={3}>
                    <Col>
                      <Form.Label>Tipo de Filtro:</Form.Label>
                      <Select
                        key={index}
                        options={filterOptions}
                        onChange={(val) => {
                          edit(filtersDefault.filter((value) => value.label === val?.label)[0], index);
                        }}
                        value={valueSelect}
                      />
                    </Col>
                  </Col>
                  <Col>
                    <FieldCreated field={value} indexSelected={index} />
                  </Col>
                  {value.type !== '' && (
                    <Col md={1} className="d-flex align-items-end justify-content-center mb-1">
                      <Button variant="outline-danger" className="ms-2" size="sm" onClick={() => removeItem(index)}>
                        <BsTrash />
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            );
          })}
          <Row>
            <Col md={3}>
              <Col>
                <Form.Label>Tipo de Filtro:</Form.Label>
                <Select
                  options={filterOptions}
                  onChange={(val) => {
                    add(filtersDefault.filter((value) => value.label === val?.label)[0]);
                  }}
                  value={{
                    label: 'Selecione um valor',
                    value: '',
                  }}
                />
              </Col>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Card>
            <Card.Body>
              <div className="mt-2">
                <h5>Filtros salvos:</h5>
                {listPlanning.length > 0 ? (
                  <Table size="sm" className="mt-2" style={{ cursor: 'pointer' }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nome do planejamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listPlanning.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.id}</td>
                            <td className="d-flex align-items-center justify-content-between">
                              <div>{value.nome}</div>
                              <div>
                                {!isOnlineConsultant && <Button variant="outline-primary" size="sm" onClick={() => applySavedFilter(value)}>
                                  Aplicar este Planejamento
                                </Button>}
                                <Button variant="outline-primary" size="sm" className="mx-1" onClick={() => buildEditSavedFilter(value)}>
                                  Editar
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => deleteSavedFilter(value)}>
                                  <BsTrash />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="m-5">Nenhum Filtro Salvo</p>
                    </div>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <ModalPlanningByCompany
        closePlanningByCompany={closePlanningByCompany}
        itemsList={itemsPlanningByCompany}
        selectItem={buildEditSavedFilter}
      />
    </>
  );
};

export default ContainerFilter;
